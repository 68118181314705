import React from "react"
import PropTypes from "prop-types"
import Layout from "../components/layout"
// Utilities
import kebabCase from "lodash/kebabCase"
import SEO from "../components/seo"
// Components
import { Link, graphql } from "gatsby"
const TagsPage = ({
    data: {
      allMarkdownRemark: { group }
    },
  }) => (
    <Layout title="Tags">
      <SEO
        title="Tags"
        keywords={[`blog`, `gatsby`, `javascript`, `react`]}
      />
      <section className="section">
        <div className="tags">
          {group.map(tag => (
            <Link
              to={`/tags/${kebabCase(tag.fieldValue)}/`}
              key={tag.fieldValue}
              className="tag is-info is-medium"
              style={{ textDecoration: 'none' }}
            >
              <span>
                {tag.fieldValue} ({tag.totalCount})
              </span>
            </Link>
          ))}
        </div>
      </section>
    </Layout>
  )

TagsPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          fieldValue: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired
      ),
    }),
  }),
}

export default TagsPage
export const pageQuery = graphql`
  query {
    allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`
